import "./styles.css";

import PartySocket from "partysocket";

import party from "party-js";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

declare const PARTYKIT_HOST: string;

let pingInterval: ReturnType<typeof setInterval>;

// Let's append all the messages we get into this DOM element
const output = document.getElementById("app") as HTMLDivElement;

// Helper function to add a new line to the DOM
function add(message_data: any) {
  let timestamp = document.createElement("span");
  timestamp.style.color = "black";
  timestamp.innerHTML = "" + message_data.timestamp + " - ";

  let name = document.createElement("span");
  name.style.color = "" + message_data.user_name_color;
  name.innerHTML = "" + message_data.user_name;

  let textMessage = document.createElement("span");
  textMessage.style.color = "black";
  textMessage.innerHTML = ": " + message_data.message + "";

  let message = document.createElement("span");
  message.appendChild(timestamp);
  message.appendChild(name);
  message.appendChild(textMessage);

  let img = new Image();
  img.width = 24;
  img.height = 24;
  img.style.paddingRight = "10px";
  img.src = message_data.user_photo_url;
  let messageBox = document.createElement("div");
  messageBox.appendChild(img);
  messageBox.appendChild(message);

  // `${message_data.timestamp} - ${message_data.user_name}: ${message_data.message}`;
  // output.appendChild(document.createTextNode(text));
  output.appendChild(messageBox);
  output.appendChild(document.createElement("br"));
}

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
console.log("PARTYKIT_HOST", PARTYKIT_HOST);
let host = "https://netclicker.andreweq.partykit.dev";
// let room_id = "rbSnOlSkVrvv0Dc5YPh82";
// let host = PARTYKIT_HOST;
let room_id = "4L1fQgP2szmv1K4rKNn5";

function decodeMessage(data: any) {
  console.log("data1", data);
  data = JSON.parse(data);
  console.log("data2", data);
  let result: any = {};
  if (data.u) {
    result.user_name = data.u.name;
    result.user_uid = data.u.uid;
    result.user_photo_url = data.u.photo_url;
    result.user_name_color = data.u.name_color;
  }
  result.type = data.t == "s" ? "system" : "user";

  if (data.t == "s") {
    switch (data.d) {
      case "cn": {
        result.message = "Connected";
        break;
      }
      case "dcn": {
        result.message = "Disconnected";
        break;
      }
      case "slow_down": {
        result.message = "Slow Down";
        break;
      }
      case "cool_off": {
        result.message = "Time to cool off, come back later...";
        break;
      }
    }
  } else if (data.t == "u") {
    const json_data = JSON.parse(data.d);
    console.log("JSON_DATA", json_data);
    switch (json_data.t) {
      case "t": {
        result.message_type = "text";
        result.message = json_data.m;
        break;
      }
      case "r": {
        result.message_type = "reaction";
        result.message = json_data.m;
        break;
      }
    }
  }

  if (data.ts) {
    result.timestamp = dayjs(data.ts).format("h:mma");
  }

  return result;
}

function encodeMessage(data: string, type: string) {
  switch (type) {
    case "text": {
      return JSON.stringify({ m: data, t: "t" });
      // break;
    }
    case "reaction": {
      return JSON.stringify({ m: data, t: "r" });
      // break;
    }
  }
}

let conn: any;
function initConversation() {
  if (conn) conn.close();

  // const room_id = 'c147c766348b5c0688808ff2ce496f35';
  let randomColor = "#000000".replace(/0/g, function () {
    return (~~(Math.random() * 16)).toString(16);
  });
  conn = new PartySocket({
    host: host + "/parties/main/" + room_id,
    // host: PARTYKIT_HOST,
    room: room_id,

    query: async () => ({
      chat_code: "F1oCNfTfVNvdX9VWLClA",
      name: nameInput.value,
      uid: "LgKBI4dxpVXRgzhj35Fj",
      photo_url: "https://assets.netclicker.tv/pf_1_13.png",
      name_color: randomColor,
    }),
  });

  conn.addEventListener("error", (error: any) => {
    console.log("errror", error);
  });
  // You can even start sending messages before the connection is open!
  conn.addEventListener("message", (event: any) => {
    console.log("event", event);
    const message_data = decodeMessage(event.data ?? {});
    console.log("message_data", message_data);
    switch (message_data.type) {
      case "system": {
        add(
          message_data
          // `${message_data.timestamp} - ${message_data.user_name} : ${message_data.message}`
        );
        break;
      }
      case "user": {
        switch (message_data.message_type) {
          case "reaction": {
            // const heartPath = document.createElementNS(
            //   "http://www.w3.org/2000/svg",
            //   "path"
            // );
            // heartPath.setAttribute(
            //   "d",
            //   "M316.722,29.761c66.852,0,121.053,54.202,121.053,121.041c0,110.478-218.893,257.212-218.893,257.212S0,266.569,0,150.801 C0,67.584,54.202,29.761,121.041,29.761c40.262,0,75.827,19.745,97.841,49.976C240.899,49.506,276.47,29.761,316.722,29.761z"
            // );

            // const heartShape = document.createElementNS(
            //   "http://www.w3.org/2000/svg",
            //   "svg"
            // );
            // heartShape.setAttribute("viewBox", "0 0 512 512");
            // heartShape.setAttribute("height", "20");
            // heartShape.setAttribute("width", "20");
            // heartShape.appendChild(heartPath);

            // party.scene.current.createEmitter({
            //   emitterOptions: {
            //     loops: 1,
            //     useGravity: false,
            //     modules: [
            //       new party.ModuleBuilder()
            //         .drive("size")
            //         .by((t) => 0.5 + 0.3 * (Math.cos(t * 10) + 1))
            //         .build(),
            //       new party.ModuleBuilder()
            //         .drive("rotation")
            //         .by((t) => new party.Vector(0, 0, 100).scale(t))
            //         .relative()
            //         .build(),
            //     ],
            //   },
            //   emissionOptions: {
            //     rate: 0,
            //     bursts: [{ time: 0, count: party.variation.skew(20, 10) }],
            //     sourceSampler: party.sources.dynamicSource(
            //       document.getElementById("likeBtn")
            //     ),
            //     angle: party.variation.range(0, 360),
            //     initialSpeed: 400,
            //     initialColor: party.variation.gradientSample(
            //       party.Gradient.simple(
            //         party.Color.fromHex("#ffa68d"),
            //         party.Color.fromHex("#fd3a84")
            //       )
            //     ),
            //   },
            //   rendererOptions: {
            //     shapeFactory: heartShape,
            //     applyLighting: undefined,
            //   },
            // });
            party.resolvableShapes[
              "like"
            ] = `<img width="30" height="30" src="thumbs-up-sign.svg"/>`;
            party.confetti(document.getElementById("fireArea"), {
              shapes: ["like"],
            });
            break;
          }
          case "text": {
            add(message_data);
            break;
          }
        }
        break;
      }
    }
  });

  // Let's listen for when the connection opens
  // And send a ping every 2 seconds right after
  // conn.addEventListener("open", () => {
  // add("Connected!");
  // add("Sending a ping every 2 seconds...");
  // TODO: make this more interesting / nice
  // clearInterval(pingInterval);
  // pingInterval = setInterval(() => {
  //   conn.send("ping");
  // }, 1000);
  // setTimeout(() => {
  //   conn.send("Hello there");
  //   setTimeout(() => {
  //     conn.send("Howsit going?");
  //   }, 10000);
  // }, 5000);
  // });
}
let nameInput: any = document.getElementById("nameInput");

// Execute a function when the user presses a key on the keyboard
nameInput.addEventListener("keypress", function (event: KeyboardEvent) {
  // If the user presses the "Enter" key on the keyboard
  if (event.key === "Enter") {
    // Cancel the default action, if needed
    event.preventDefault();
    initConversation();
  }
});

// Get the input field
let messageInput: any = document.getElementById("messageInput");

// Execute a function when the user presses a key on the keyboard
messageInput.addEventListener("keypress", function (event: KeyboardEvent) {
  // If the user presses the "Enter" key on the keyboard
  if (event.key === "Enter") {
    // Cancel the default action, if needed
    event.preventDefault();
    // Trigger the button element with a click
    // document.getElementById("myBtn").click();
    conn.send(encodeMessage(messageInput.value, "text"));
    // messageInput.value = "";
  }
});

let likeBtn: any = document.getElementById("likeBtn");
// Execute a function when the user presses a key on the keyboard
likeBtn.addEventListener("click", function (event: MouseEvent) {
  // If the user presses the "Enter" key on the keyboard

  // Cancel the default action, if needed
  event.preventDefault();
  // Trigger the button element with a click
  // document.getElementById("myBtn").click();
  conn.send(encodeMessage("like", "reaction"));
  // messageInput.value = "";
});
